import React, { useEffect, useState } from 'react';
import { ActionButton, Content, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, TextField, View } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './ExpenseLineComponent';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

interface TimesheetLineAddEditProps {
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  styles: any;
}

const TimesheetLineAddEdit: React.FC<TimesheetLineAddEditProps> = ({
  id,
  defaultRowData,
  selectedRowData,
  typeofWorkList,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);

  const [validationMessages, setValidationMessages] = useState<any>({ typeOfWork: '', entryDate: '', qty: '', description: '' });

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    //console.log('id', id);
    setValidationMessages(selectedRow.ident !== 0 ? { typeOfWork: '', entryDate: '', qty: '', description: '' } : { typeOfWork: 'required', entryDate: '', qty: '', description: '' });
    setSelectedRow(selectedRowData || defaultRowData);
  }, [id, selectedRowData]);

  const validateFields = () => !['typeOfWork', 'entryDate', 'qty'].some(field => validationMessages[field] !== '');

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.ident) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    closeDialog(false);
    action(selectedRow);
  };

  return (
    <>
      {isMobile ? (
        <Flex direction="column" marginTop={'size-200'}>
          <View>
            <Picker
              label="Type of expense"
              isRequired
              width={'100%'}
              items={typeofWorkList}
              onSelectionChange={value => {
                setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                const typeOfWorkName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, typeOfWorkName }));
              }}
              selectedKey={selectedRow.typeOfWorkId}
              validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
            </Picker>
          </View>
          <View>
            <label className="A-HlBa_spectrum-FieldLabel">
              Date ​
              <svg className="yxBNXG_spectrum-Icon yxBNXG_spectrum-UIIcon-Asterisk A-HlBa_spectrum-FieldLabel-requiredIcon" focusable="false" aria-hidden="true" role="img">
                <path d="M6.573 6.558c.056.055.092.13 0 .204l-1.148.74c-.093.056-.13.02-.167-.073L3.832 4.947l-1.87 2.055c-.02.037-.075.074-.13 0l-.889-.926c-.092-.055-.074-.111 0-.167l2.111-1.76-2.408-.906c-.037 0-.092-.074-.055-.167l.63-1.259a.097.097 0 0 1 .166-.036l2.111 1.37.13-2.704a.097.097 0 0 1 .111-.11L5.277.54c.092 0 .11.037.092.13l-.722 2.647 2.444-.74c.056-.038.111-.038.148.073l.241 1.37c.019.093 0 .13-.074.13l-2.556.204z"></path>
              </svg>
            </label>
            <DatePickerComponent
              className="YO3Nla_spectrum-Textfield-input YO3Nla_i18nFontFamily"
              id="datepickermobile"
              width={'100%'}
              value={new Date(selectedRow.entryDate)}
              onChange={(value: any) => {
                //console.log('datepicker', value.value);
                setSelectedRow(prev => ({ ...prev, entryDate: String(value.value) }));
              }}
            />
          </View>
          <View>
            <NumberField
              width={'100%'}
              value={Number(selectedRow.qty)}
              isRequired
              minValue={1}
              label="Quantity"
              onChange={value => {
                validationMessages.qty = isNaN(value) || value <= 0 ? 'required' : '';
                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                setSelectedRow((prev: any) => ({ ...prev, qty: String(value) }));
              }}
              validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
            />
          </View>
          <View>
            <TextField label={'Description'} width={'100%'} value={selectedRow.description} onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))} />
          </View>
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }} cellSpacing={0}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Picker
                    label=""
                    width={'100%'}
                    items={typeofWorkList}
                    onSelectionChange={value => {
                      setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                      const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                      setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                    }}
                    selectedKey={selectedRow.typeOfWorkId}
                    validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                  </Picker>
                </span>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <div style={{ marginTop: navigator.userAgent.includes('Safari') && !navigator.userAgent.includes('Chrome') ? '-5px' : '0' }}>
                  <DatePickerComponent
                    width={'100%'}
                    id="datepicker"
                    className="YO3Nla_spectrum-Textfield-input YO3Nla_i18nFontFamily"
                    value={new Date(selectedRow.entryDate)}
                    onChange={(value: any) => {
                      //console.log('datepicker', value.value);
                      setSelectedRow(prev => ({ ...prev, entryDate: String(value.value) }));
                    }}
                  />
                </div>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <NumberField
                    width={'100%'}
                    value={Number(selectedRow.qty)}
                    hideStepper
                    isRequired
                    minValue={1}
                    label=""
                    onChange={value => {
                      validationMessages.qty = isNaN(value) || value <= 0 ? 'Qty must be greater than 0' : '';
                      setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                      setSelectedRow(prev => ({ ...prev, qty: String(value) }));
                    }}
                    validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
                  />
                </span>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <TextField width={'100%'} value={selectedRow.description} onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))} />
                </span>
              </th>
            </tr>
          </thead>
        </table>
      )}

      <Flex direction={{ base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-175'} marginTop={'size-200'}>
        <Flex>
          {Number(selectedRowData.ident) !== 0 && (
            <Flex>
              <DialogTrigger isDismissable type="modal">
                <ActionButton UNSAFE_className={styles.btn_delete}>Remove expense</ActionButton>
                {close => (
                  <Dialog size="S">
                    <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                      <Content UNSAFE_className={styles.delete_pop_up_header}>Remove expense </Content>
                      <View UNSAFE_className={styles.delete_pop_up_line}></View>
                      <View UNSAFE_className={styles.delete_pop_up_warning_text}>Are you sure, you want to remove expense?</View>
                      <View UNSAFE_className={styles.buttons_parent}>
                        <View UNSAFE_className={styles.button_cancel}>
                          <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                            No, keep it.
                          </Link>
                        </View>
                        <View UNSAFE_className={styles.button_confirm}>
                          <div className={styles.trash_icon}>
                            <TrashIcon size={32} />
                          </div>
                          <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
                            Yes,remove!
                          </Link>
                        </View>
                      </View>
                      <View UNSAFE_className={styles.ellipse_div}>
                        <View UNSAFE_className={styles.delete_alert_icon}>
                          <AlertIconFill size={72} />
                        </View>
                      </View>
                    </Flex>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          )}
        </Flex>
        <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
          <Flex>
            <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
              Cancel
            </Link>
          </Flex>
          <Flex>
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
              Confirm expense
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TimesheetLineAddEdit;
