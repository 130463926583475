import React, { useState } from 'react';
import { View, Flex, TextArea } from '@adobe/react-spectrum';
import ContextMenuButton from './ContextMenuButton';
import styles from '../ResponsibleUsers.module.css';

interface ContactCardProps {
  currentItem: any;
  contact: any;
  avatarSrc: string;
  selectedItem: any;
  FPADataTypes: any;
  onSaveDescription: (contactId: string, description: string) => void;
  updateItem: (request: any, message: string) => void;
  closeDialog: (reload: boolean) => void;
}

const ContactCard: React.FC<ContactCardProps> = ({ currentItem, contact, avatarSrc, selectedItem, FPADataTypes, onSaveDescription, updateItem, closeDialog }) => {
  const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(contact?.description);

  const handleSaveDescription = () => {
    onSaveDescription(contact.id, description);
    //console.log('description', description);
    setIsTextAreaFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      onSaveDescription(contact.id, description);
      //console.log('description', description);
      setIsTextAreaFocused(false);
    }
  };

  return (
    <View key={contact.id} UNSAFE_className={styles.contact_parent}>
      <View UNSAFE_className={styles.contact_avatar_parent}>
        <img className={styles.contact_avatar} src={avatarSrc} alt="" />
      </View>
      <View UNSAFE_className={styles.contact_name}>
        {contact?.isPrimary === '1' && (
          <i className="bi bi-star-fill" style={{ color: '#F4CA16' }}>
            &nbsp;
          </i>
        )}
        {contact?.userName}
      </View>
      <Flex UNSAFE_className={styles.description_parent} UNSAFE_style={{ top: '70px' }}>
        <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'} height={'24px'}>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
            <View position={'relative'}>Note</View>
          </Flex>
        </Flex>
        <div style={{ width: '100%' }} onKeyUp={handleKeyUp}>
          <TextArea
            aria-label="note"
            key={`txt_${contact.id}`}
            defaultValue={contact?.note}
            value={description}
            onChange={e => setDescription(e)}
            onFocus={() => setIsTextAreaFocused(true)}
            width={'100%'}
            minHeight={'200px'}
            UNSAFE_style={{ height: '108px !important' }}
          ></TextArea>
        </div>
        {isTextAreaFocused && (
          <Flex UNSAFE_className={styles.activity_btn_container}>
            <Flex UNSAFE_className={styles.activity_btn_master}>
              <Flex UNSAFE_className={styles.activity_btn_child}>
                <Flex UNSAFE_className={styles.btn_cancel_desc}>
                  <button
                    onClick={() => {
                      setIsTextAreaFocused(false);
                    }}
                    className={`${styles.icon_cancel} bi bi-x fs-3`}
                  ></button>
                </Flex>
                <Flex UNSAFE_className={styles.btn_save_desc}>
                  <button
                    onClick={() => {
                      handleSaveDescription();
                    }}
                    className={`${styles.icon_add} bi bi-check fs-3`}
                  ></button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <ContextMenuButton contactId={contact.id} updateItem={updateItem} closeDialog={closeDialog} />
    </View>
  );
};

export default ContactCard;
