import React, { useCallback } from 'react';
import { Text, Item, Link, Menu, MenuTrigger, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import { DeleteActivityUserRequest } from '../../../../../services/soap/team/requests/DeleteActivityUserRequest';
import { DeleteProjectUserRequest } from '../../../../../services/soap/team/requests/DeleteProjectUserRequest';
import { DeleteFolderUserRequest } from '../../../../../services/soap/team/requests/DeleteFolderUserRequest';
import { FPADataTypes } from '../../../../../infra/protected/FPA/FPAData';
import styles from '../ResponsibleUsers.module.css';

interface ContextMenuButtonProps {
  contactId: string;
  updateItem: (request: any, message: string) => void;
  closeDialog: (reload: boolean) => void;
}

const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ contactId, updateItem, closeDialog }) => {
  const { projectService, teamService, store } = useDependency();
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);

  const deleteContact = useCallback(
    async (contactId: string) => {
      if (!selectedItem) return;

      switch (selectedItem.type) {
        case FPADataTypes.ACTIVITY:
          await updateItem(
            () =>
              teamService.deleteActivityUser(
                new DeleteActivityUserRequest(store.Server, store.SessionId, {
                  id: contactId,
                })
              ),
            'User deleted successfully'
          );
          break;
        case FPADataTypes.PROJECT:
          await updateItem(
            () =>
              teamService.deleteProjectUser(
                new DeleteProjectUserRequest(store.Server, store.SessionId, {
                  id: contactId,
                })
              ),
            'User deleted successfully'
          );
          break;
        case FPADataTypes.FOLDER:
          await updateItem(
            () =>
              teamService.deleteFolderUser(
                new DeleteFolderUserRequest(store.Server, store.SessionId, {
                  id: contactId,
                })
              ),
            'User deleted successfully'
          );
          break;
      }
      closeDialog(true);
    },
    [contactId]
  );

  const menuAction = (key: string) => {
    switch (key) {
      case 'email':
        console.log('email');
        break;
      case 'sms':
        console.log('sms');
        break;
      case 'call':
        console.log('call');
        break;
      case 'delete':
        console.log('delete');
        deleteContact(contactId);
        break;
      default:
        break;
    }
  };
  return (
    <View key={`view_${contactId}`} UNSAFE_className={styles.icon_more}>
      <MenuTrigger key={`menu_responsible_user_${contactId}`}>
        <Link>
          <span key={`span_menu_responsible_user_${contactId}`} aria-label="Open context menu" role="button" tabIndex={0}>
            <i className="bi bi-three-dots fs-7"></i>
          </span>
        </Link>
        <Menu width={110} onAction={(key: any) => menuAction(key)}>
          <Item key="delete" textValue="delete">
            <i className="bi bi-trash" style={{ paddingTop: '5px', paddingLeft: '5px', color: '#B0BEC5', WebkitTextStroke: '.5px' }}></i>&nbsp;&nbsp;
            <Text UNSAFE_style={{ color: '#36474F', paddingLeft: '5px' }}>Remove</Text>
          </Item>
        </Menu>
      </MenuTrigger>
    </View>
  );
};

export default ContextMenuButton;
