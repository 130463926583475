export const MAIN = {
    login: "Login",
    logout: "Logout",
    contactDataTransfer: "ContactDataTransfer",
    getContact: "GetContact",
    listDistributionGroup: "ListDistributionGroup",
    getContactList: "GetContactList",
    listReference: "ListReference",
    listContactList: "ListContactList",
    createContact: "CreateContact",
    getFromContactList: "GetFromContactList",
};

export const DOCUMENT_LIBRARY = {
    listDocument: "ListDocument",
    listDocumentType: "ListDocumentType",
    listDocumentCategory: "ListDocumentCategory",
    listDocumentLocation: "ListDocumentLocation",
    permanentlyDeleteDocument: "PermanentlyDeleteDocument",
    createDocumentInStorage: "CreateDocumentInStorage",
};

export const FEATURES = {
    listApplications: "ListApplications",
    updateRegister: "UpdateRegister",
    listRegister: "ListRegister",
    createRegister: "CreateRegister",
    getFormPanel: "GetFormPanel",
}

export const INVOICE = {
    listInvoice: "ListInvoice"
}

export const ACCESS = {
    listUser: "ListUser",
    getUser: "GetUser",
    listRole: "ListRole",
    listProfile: "ListProfile",
};

export const PROJECT = {
    listFPA: "ListFPA",
    listContext: "ListContext",
    updateFavouriteContext: "UpdateFavouriteContext",
    listFolderStatus: "ListFolderStatus",
    listProjectStatus: "ListProjectStatus",
    listActivityStatus: "ListActivityStatus",
    listMasterWithSlaves: "ListMasterWithSlaves",
    listProjectType: "ListProjectType",
    listFolderType: "ListFolderType",
    getProject: "GetProject",
    getActivity: "GetActivity",
    getMarketingChannelsStats: "GetMarketingChannelsStats",
    getContextPath: "GetContextPath",
    getFolder: "GetFolder",
    listFPATypeCategory: "ListFPATypeCategory",
    updateActivity: "UpdateActivity",
    updateFolder: "UpdateFolder",
    updateProject: "UpdateProject",
    getFolderProjectType: "GetFolderProjectType",
}

export const RECRUITMENT = {
    listCandidateVacancy: "ListCandidateVacancy",
    updateCandidateVacancy: "UpdateCandidateVacancy",
    listClientProject: "ListClientProject",
    listClientProjectPackage: 'ListClientProjectPackage',
    listPlacementConfiguration: 'ListPlacementConfiguration',
    getSelectionProcedureStats: 'GetSelectionProcedureStats',
}

export const SCHEDULER = {
    getSchedulerSettings: "GetSchedulerSettings",
    listTimeSheet: "ListTimeSheet",
    createTimeSheet: "CreateTimeSheet",
    updateTimeSheet: "UpdateTimeSheet",
    deleteTimeSheet: "DeleteTimeSheet",
    getTypeOfWorkForContext: "GetTypeOfWorkForContext",
    evaluateTimeSheet: "EvaluateTimeSheet",
    createTask: "CreateTask",
    updateTask: "UpdateTask",
    listTask: "ListTask",
    deleteTask: "DeleteTask",
}

export const ITEM = {
    listProjectItemCalculation: "ListProjectItemCalculation",
    listProjectItem: "ListProjectItem",
    listItem: "ListItem",
    deleteProjectItem: "DeleteProjectItem",
    createProjectItem: "CreateProjectItem",
    updateProjectItem: "UpdateProjectItem",
    getItem: "GetItem",
}

export const FORM = {
    listItemValue: "ListItemValue",
    updateFormItemValues: "UpdateFormItemValues",
    updateItemValue: "UpdateItemValue",
    createFormValue: "CreateFormValue",
    listForms: "ListForms",
}

export const TREE = {
    nodeToPath: "NodeToPath",
}

export const TRANSLATE = {
    listLanguages: "ListLanguages",
    listCountry: "ListCountry",
}

export const FINANCE = {
    getFinanceSettings: "GetFinanceSettings",
    listCurrencyRate: "ListCurrencyRate",
}

export const GLOBAL = {
    listCurrency: "ListCurrency",
    listVAT: "ListVAT",
}

export const TEAM = {
    listActivityUser: "ListActivityUser",
    newActivityUser: "NewActivityUser",
    listProjectUser: "ListProjectUser",
    listFolderUser: "ListFolderUser",
    updateActivityUser: "UpdateActivityUser",
    deleteActivityUser: "DeleteActivityUser",
    updateProjectUser: "UpdateProjectUser",
    deleteProjectUser: "DeleteProjectUser",
    updateFolderUser: "UpdateFolderUser",
    deleteFolderUser: "DeleteFolderUser",
    newFolderUser: "NewFolderUser",
    newProjectUser: "NewProjectUser",
}