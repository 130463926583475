import { Flex, View } from '@adobe/react-spectrum';
import styles from './Dashboard.module.css';
import jsonPackage from '../../../package.json';
import { useDependency } from '../../contexts/DependencyProvider';
import { ListContactListRequest } from '../../services/soap/main/requests/ListContactListRequest';
import { GetFromContactListRequest } from '../../services/soap/main/requests/GetFromContactListRequest';
import { ListCountryRequest } from '../../services/soap/translate/reqeusts/ListCountryRequest';
import { ListActivityUserRequest } from '../../services/soap/team/requests/ListActivityUserRequest';
import { NewActivityUserRequest } from '../../services/soap/team/requests/NewActivityUserRequest';
import { GetContactListRequest } from '../../services/soap/main/requests/GetContactListRequest';
import { ListProjectUserRequest } from '../../services/soap/team/requests/ListProjectUserRequest';
import { ListFolderUserRequest } from '../../services/soap/team/requests/ListFolderUserRequest';
import { UpdateActivityUserRequest } from '../../services/soap/team/requests/UpdateActivityUserRequest';
import { DeleteActivityUserRequest } from '../../services/soap/team/requests/DeleteActivityUserRequest';
import { UpdateFolderUserRequest } from '../../services/soap/team/requests/UpdateFolderUserRequest';

const Dashboard = () => {
    const { 
      mainService, 
      featureService, 
      translateService, 
      projectService, 
      formService,
      itemService, 
      financeService,
      globalService,
      schedulerService,
      accessService,
      printRestService,
      documentLibraryService,
      teamService,
      store 
    } = useDependency();
    const today = new Date();

    const folderId = 524524101;
    const projectId = 524587101;

    const handleClick = async () => {
      try {
        // const res1 = await teamService.listProjectUser(new ListProjectUserRequest(store.Server, store.SessionId,  projectId));
        // console.log('res1: ', res1);

        // const res2 = await teamService.listFolderUser(new ListFolderUserRequest(store.Server, store.SessionId,  folderId));
        // console.log('res2: ', res2);

        // ------- Unable to test -------
        // const res3 = await teamService.updateActivityUser(new UpdateActivityUserRequest(store.Server, store.SessionId,  {
        //   roleName: 'roleName',
        //   role: 'role',
        //   note: 'note',
        //   id: 'id',
        //   userId: 'userId',
        //   activityName: 'activityName',
        //   acl: 'acl',
        //   activity: 'activity',
        //   isPrimary: 'isPrimary',
        //   contact: 'contact',
        //   userName: 'userName'
        // }));

        // console.log('res3: ', res3);

        // ------- Unable to test -------
        // const res4 = await teamService.deleteActivityUser(new DeleteActivityUserRequest(store.Server, store.SessionId,  {
        //   id: 530607101
        // }));

        // console.log('res4: ', res4);

        // ------- Unable to test -------
        // const res5 = await teamService.updateFolderUser(new UpdateFolderUserRequest(store.Server, store.SessionId,  {
        //   id: 524528101,
        //   userId: 'userId',
        //   folderName: 'folderName',
        //   acl: 'acl',
        //   folder: 'folder',
        // } ));

        // console.log('res5: ', res5);

      } catch (error) {
        console.log('error', error);
      }
    };
    return ( <>
        
        <div className={styles.centerText} onClick={handleClick}>
            Welcome to Atollon
        </div>
        <Flex direction="column" alignItems="center">
              <View>
                <p style={{ paddingTop: '10px' }}>
                  v{jsonPackage.version} Copyright by Atollon {today.getFullYear()}
                </p>
              </View>
        </Flex>
    </> );
}
 
export default Dashboard;